import { Turbo } from "@hotwired/turbo-rails"
window.Turbo = Turbo
// Turbo.session.drive = false; // disable turbo

import { Application } from "@hotwired/stimulus"
const application = Application.start()
window.Stimulus = application

// Hack to break out of frame from the server and redirect
// https://github.com/hotwired/turbo-rails/pull/367#issuecomment-1934729149
// Usage: in rails controller:
// render turbo_stream: turbo_stream.action(:redirect, path)
Turbo.StreamActions.redirect = function () {
  Turbo.visit(this.target, { action: "replace" });
};

import * as bootstrap from 'bootstrap'

// Register Stimulus controllers here
import GalleryControllery from "./controllers/gallery_controller";
application.register("gallery", GalleryControllery);

import NavbarController from "./controllers/navbar_controller";
application.register("navbar", NavbarController);

import NavbarTogglerController from "./controllers/navbar_toggler_controller";
application.register("navbar-toggler", NavbarTogglerController);

import TimeagoController from "./controllers/timeago_controller";
application.register("timeago", TimeagoController);

import CommentFormController from "./controllers/comment_form_controller";
application.register("comment-form", CommentFormController);
